import App from './clientApp';
//import Player from '../player';
import ClientObject from './clientObject';
import PIXI, {Container, Text} from 'pixi.js';
import ClientMap from './clientMap';
import ServerTick from './serverTick';
import Vector2 from '../lib/vector2';
import _ from 'lodash';

class Stage {

	public id: string = 'world';
	public container: Container = new Container();
	public tickObjects: Map<number, ClientObject> = new Map();
	public newObjectIds: number[] = [];
	public backgroundLayer: Container = new Container();
	public objectsLayer: Container = new Container();
	public uiLayer: Container = new Container();
	public player: ClientObject;
	public serverTicks: ServerTick[] = [];
	public lastTickTime: number = 0;

	constructor() {
		const self = this;
		// contents
		self.container = App.game.stage;
		self.container.sortableChildren = true;
		self.objectsLayer.sortableChildren = true;

		self.container.addChild( self.backgroundLayer );
		self.container.addChild( self.objectsLayer );
		self.container.addChild( self.uiLayer );
	}

	load() {
		const self = this;

		// map
		// this logic should probably be inside the map, or a subclass of it
		App.map = new ClientMap();
		App.map.generateAndAdd();

		// player
		//self.player.loadAndAdd();

		const versionText = new Text('v' + App.version, {
			fontFamily: 'Arial',
			fontSize: 12,
			fill: 0xcccccc,
			align: 'right'
		});
		versionText.x = 10;
		versionText.y = 30;
		versionText.zIndex = 10;
		self.container.addChild( versionText );
	}

	public tick(delta : number) {
		const self = this;
		const now = Date.now();
		// process relevant data from serverTicks
		// TODO: maybe move to clientObject
		const data = self.getInterpTickData( Date.now() - App.interpDelay );

		const idsSent: any[] = [];
		for (const i in data) {
			const o: any = data[i];
			idsSent.push( o.id );

			if (!self.tickObjects.has( o.id )) {
				//App.socket?.close();
				//App.tick = function(){};
				console.error('state for non-existent object', o, data, self.tickObjects);
				break;
				//throw new Error('crash');
			}

			const to: ClientObject = self.tickObjects.get( o.id ) as ClientObject;
			for (const k in o ) {
				to[ k ] = o[k];
			}
			to.lastUpdate = now;
			to.lastData = o;
			to.tick( delta );
		}

		// let's check for missing state data and removals
		for (const [i, o] of self.tickObjects) {
			//console.log('- checking', idsSent, 'for', i, 'from', data);
			const last = self.tickObjects.get(i)?.lastUpdate || 0;
			// has this object been missing more than the interp delay? (it may be new)
			if (idsSent.indexOf(i) == -1 && now - last > App.interpDelay * 2) {
				console.log('- missing state data, removing:', now - last, self.tickObjects.get(i));
				o.destroy();

				self.tickObjects.delete( i );
			}
		}

		// center world on player
		if (!self.player || !self.player.sprite) return;
		const winX = window.innerWidth / 2;
		const winY = window.innerHeight / 2;

		self.container.x = -1 * (self.player.sprite.x - winX);
		self.container.y = -1 * (self.player.sprite.y - winY);
	}

	/**
	 * Gets the data from the two ticks bordering the render time and interpolates them
	 * @param renderTime - the target time in ms
	 * @return interpolated tick state data
	 */
	public getInterpTickData( renderTime: number ): any[] {
		const self = this;

		// loop through ticks until we find the two ticks bordering the render time
		let lastChecked!: ServerTick;
		let firstTick!: ServerTick;
		let secondTick!: ServerTick;

		for (const t of self.serverTicks) {
			//const t = self.serverTicks[i];

			//console.log('- tick time:', t.tickNum, t.time, renderTime - t.time);

			// is this tick before our render time?
			if (t.time <= renderTime) {
				firstTick = t;

				if (!lastChecked) {
					console.log('- packet loss detected, no interp', renderTime - t.time);
					secondTick = t;
				} else {
					secondTick = lastChecked;
				}
				break;
			}

			lastChecked = t;
		}

		if (!firstTick || !secondTick) {
			console.log('- not enough data found for tickin');
			return []; // not enough data yet, we need to wait a tick probably
		}

		// calculate interp amount
		const tDelta: number = secondTick.time - firstTick.time;
		const tElapsed: number = renderTime - firstTick.time;
		const interp: number = tElapsed / tDelta;

		// interpolate objects
		const interpolated: any[] = [];
		for (const i in firstTick.data) {
			const o: any = firstTick.data[i];

			// get future version
			const f: any = _.find(secondTick.data, function(t) { return t.id == o.id && t.you == o.you; });
			if (!f) {
				// if this doens't exist, the object probably died, let's kill it
				const to = self.tickObjects.get( o.id );
				//console.log('-', to?.type, 'destroyed');
				if (to) to.destroy();
				if (to) self.tickObjects.delete( o.id );
				continue;
			}

			// iterate fields and interp ones we can (number | Vector2)
			const no: any = {};
			for (const k in o) {
				let v = o[k];
				let v2 = f[k];
				//console.log('- type of', k, v, typeof v);

				if (typeof v == 'number' && k != 'id' && k != 'you') {
					no[k] = Stage.lerp(v, v2, interp);
				} else
				if (typeof v == 'object' && v.x && v.y) { // Vector2
					v = new Vector2(v.x, v.y);
					v2 = new Vector2( v2.x, v2.y);
					no[k] = v.lerp(v2, interp);
				} else {
					// not interp-able, just set to original value
					no[k] = v;
				}
			}
			interpolated.push( no );
		}
		
		// clear new object ids, we've got a valid state
		self.newObjectIds = [];

		// return it
		return interpolated;
	}

	/**
	 * Helper function to linearly interpolate between two numbers
	 * @param one - first value
	 * @param two - second value
	 * @param interp - interpolation amount, between 0 and 1
	 * @return interpolated value
	 */
	private static lerp( one: number, two: number, interp: number): number {
		// clamp to 0 and 1
		interp = interp < 0 ? 0 : interp;
		interp = interp > 1 ? 1 : interp;

		return one + (two - one) * interp;
	}

	public handleObjectDump( objects: any[] ) {
		const self = this;

		//console.log('- object dump:', objects);
		for (const i in objects) {
			const o = objects[i];
			let to: ClientObject;
			if (!self.tickObjects.has( o.id ) ) {
				// new object
				//console.log('- spawning', o.type, o);
				to = new ClientObject(o);
				self.tickObjects.set( o.id, to );
				self.newObjectIds.push( o.id );
				//console.log('- tick objs:', self.tickObjects);
			} else {
				// existing object, update it
				to = self.tickObjects.get( o.id ) as ClientObject;
				for (const k in o ) {
					to[ k ] = o[k];
				}
			}

			if (to.you) self.player = to;
		}
	}

	public handleState( tickNum: number, objects: any[] ) {
		const self = this;

		self.serverTicks.unshift( new ServerTick(tickNum, objects ) );

		// truncate to last 10 ticks
		self.serverTicks = self.serverTicks.slice(0, 10);
	}

	public handleEvent( message ) {

	}
}

export default Stage;