import Vue from 'vue';
import BosonComponent from '../component';
import Controls from './controls.component';
import App from '../../game/clientApp';
import $j from 'jquery';

/**
 * Overall Game View
 */
class Game extends BosonComponent {

	public name: string = 'game/game';
	public extra: string = 'var that will not be used.';
	public controls: Controls;
	public connection: any;

	public constructor() {
		super();
		
		this.initComponent();
	}

	public init() {
		const self = this;
		super.init();

		console.log('- game init');

		const controls = new Controls();
		self.controls = controls;
		controls.mount( '#controls' );
		controls.init();

	}

	public onMount() {
		const self = this;

		App.init( self.connection );

		window.onresize = function() { 
			setTimeout(function() {
				App.resize();
			}, 50);
		};
	}

}

export default Game;