
class Vector2 {

	public x: number;
	public y: number;

	// constants
	public static TO_DEGREES = 180 / Math.PI;
	public static TO_RADIANS = Math.PI / 180;
	public static HALF_PI = Math.PI / 2;

	constructor(x?: number, y?: number) {
		this.x = x || 0.0;
		this.y = y || 0.0;
	}

	static FromObject( object: any ) {
		if (object.x === undefined || object.y === undefined) console.error('Vector2.FromObject called on non-vector object:', object);
		return new Vector2( object.x, object.y );
	}

	/**
	 * Takes the coordinates and converts them to fixed values so they don't take up a ton of space
	 * @param digits - amount of digits to convert to
	 * @return the new slightly less accurate Vector2
	 */
	toFixed( digits: number = 3 ) {
		const v = this.clone();
		v.x = +v.x.toFixed( digits ); // the + converts it back to a number, Number.toFixed() makes a string
		v.y = +v.y.toFixed( digits );

		return v;
	}

	/**
	 * Linearly interpolates between two vectors
	 * this - first vector
	 * @param v2 - second vector
	 * @param interp - interpolation amount (between 0 and 1)
	 * @return interpolated vector
	 */
	lerp(v2: Vector2, interp: number): Vector2 {
		// clamp to 0 and 1
		interp = interp < 0 ? 0 : interp;
		interp = interp > 1 ? 1 : interp;

		const v1 = this;

		return new Vector2(v1.x + (v2.x - v1.x) * interp, v1.y + (v2.y - v1.y) * interp);
	}
	
	/**
	 * Returns if this vector is equal to the comparison vector
	 * @param v - comparison vector
	 * @return equal or not
	 */
	equals(v: Vector2): boolean {
		return v.x == this.x && v.y == this.y;
	}
	
	/**
	 * Adds vector to this one and returns, original is not modified
	 * @param v - the vector to add
	 * @return the resulting vector
	 */
	add(v: Vector2): Vector2 {
		return new Vector2(this.x + v.x, this.y + v.y);
	}
	
	/**
	 * Subtracts vector from this one and returns, original is not modified
	 * @param v - the vector to subtract
	 * @return the resulting vector
	 */
	subtract(v: Vector2): Vector2 {
		return new Vector2(this.x - v.x, this.y - v.y);
	}
	
	/**
	 * @alias Vector2.subtract
	 */
	sub(v: Vector2): Vector2 {
		return this.subtract(v);
	}
	
	/**
	 * Flips the vector and returns, original is not modified
	 * @return flipped vector
	 */
	reverse(): Vector2 {
		return new Vector2(0 - this.x, 0 - this.y);
	}
	
	/**
	 * Returns a new copy of this vector
	 * @return cloned vector
	 */
	clone(): Vector2 {
		return new Vector2(this.x, this.y);
	}

	/**
	 * Copies the values of this vector to the target vector
	 * @param v - the target vector
	 */
	copyTo(v: Vector2) {
		v.x = this.x;
		v.y = this.y;
	}

	/**
	 * Copies the values from target vector to this vector
	 * @param v - the target vector
	 */
	copyFrom(v: Vector2) {
		this.x = v.x;
		this.y = v.y;
	}
	
	/**
	 * Multiplies this vector by a scalar and returns. Original unmodified.
	 * @param scalar - the scalar to multiply by
	 * @return scaled vector
	 */
	multiply(scalar: number): Vector2 {
		return new Vector2(this.x * scalar, this.y * scalar);
	}
	
	/**
	 * Returns the magnitude of current vector
	 * @return magnitude
	 */
	magnitude(): number {
		return Math.sqrt(this.x * this.x + this.y * this.y);
	}
	
	/**
	 * Returns magnitude squared of current vector (for physics)
	 * @return squared magnitude
	 */
	magnitudeSquared(): number {
		return this.x * this.x + this.y * this.y;
	}
	
	/**
	 * Checks if vector is zeroed out
	 * @return zeroed or not
	 */
	isZero(): boolean {
		return (this.x == 0 && this.y == 0);
	}
	
	/**
	 * Returns normalized version of current vector. Original unmodified.
	 * @return normalized vector
	 */
	normalize(): Vector2 {
		const len = this.magnitude();
		if (len == 0.0) return new Vector2();

		return new Vector2(this.x / len, this.y / len);
	}
	
	/**
	 * Returns dot product of this vector and target vector
	 * @param v - target vector
	 * @return dot product
	 */
	dot(v: Vector2): number {
		return this.x * v.x + this.y * v.y;
	}
	
	/**
	 * Returns cross product of this vector and target vector
	 * @param v - target vector
	 * @return cross product
	 */
	cross(v: Vector2): number {
		return this.x * v.y - this.y * v.x;
	}
	
	/**
	 * Checks for intersection between two lines segments
	 * @param this - the first coordinate of the first line segment
	 * @param x2 - second coordinate of first line segment
	 * @param y1 - first coordinate of the second line segment
	 * @param y2 - second coordinate of second line segment
	 * @return where the segments intersect or `false`
	 */
	intersect(x2: Vector2, y1: Vector2, y2: Vector2): Vector2|boolean {
		if (x2.equals(y2)) {
			return y2;
		}
		const x1 = this;
		const c = y1.subtract(x1);
		const r = x2.subtract(x1);
		const s = y2.subtract(y1);
		const cxr = c.cross(r);
		const cxs = c.cross(s);
		const rxs = r.cross(s);
		if (rxs == 0 || cxr == 0) return false;
		const t = cxs / rxs,
			u = cxr / rxs;
		if (t <= 1 && t >= 0 && u <= 1 && u >= 0) {
			return x1.add(r.multiply(t));
		}
		return false;
	}
	
	/**
	 * Returns a vector rotated from this one towards the target, given the max angle delta
	 * @param v - target vector
	 * @param maxAngleDelta - maximum angle change allowed
	 * @return resulting vector
	 */
	rotateTowards(v: Vector2, maxAngleDelta: number): Vector2 {
		const a1 = this.angle();
		const a2 = v.angle();
		
		let delta = a2 - a1;
		
		if (delta > 180) delta = -1 * (360 - delta);
		if (delta < -180) delta = 360 + delta;
		
		const adelta = Math.abs(delta);
		let fdelta = delta;
		if (adelta > maxAngleDelta) {
			fdelta = maxAngleDelta * adelta / delta;
		}
		
		const nv = this.clone();
		
		return nv.rotate(fdelta);
	}
	
	/**
	 * Returns the angle of current vector
	 * @param useRadians - (optional)
	 * @return the angle
	 */
	angle(useRadians?: boolean): number {
		return Math.atan2(this.y,this.x) * (useRadians ? 1 : Vector2.TO_DEGREES);
	}
	
	/**
	 * Returns a copy of this vector rotated by `angle`
	 * @param angle - angle amount to rotate
	 * @param useRadians - (optional)
	 * @return rotated vector
	 */
	rotate(angle: number, useRadians?: boolean): Vector2 {
		if (!angle) return this.clone();

		const cosRY = Math.cos(angle * (useRadians ? 1 : Vector2.TO_RADIANS));
		const sinRY = Math.sin(angle * (useRadians ? 1 : Vector2.TO_RADIANS));

		const temp = this.clone();

		const v = new Vector2(
			(temp.x * cosRY) - (temp.y * sinRY),
			(temp.x * sinRY) + (temp.y * cosRY)
		);

		return v; 
	}
	
	/**
	 * Returns a copy of this vector rotated around a point
	 * @param angle - amount to rotate
	 * @param pos - point to rotate around
	 * @param useRadians - (optional)
	 * @return rotated vector
	 */
	rotateAroundPoint(angle: number, pos: Vector2, useRadians?: boolean): Vector2 {
		if (!angle) return this.clone();
		const theta = angle * (useRadians ? 1 : Vector2.TO_RADIANS);
		
		const v = new Vector2(
			(Math.cos(theta) * (this.x - pos.x) - Math.sin(theta) * (this.y - pos.y)) + pos.x,
			(Math.sin(theta) * (this.x - pos.x) + Math.cos(theta) * (this.y - pos.y)) + pos.y
		);
		
		return v;
	}
	
	/**
	 * Cancels out the current vector using the target vector
	 * @param vec - target vector
	 * @result cancelled out copy
	 */
	cancelOut(vec: Vector2): Vector2 {
		const v = this.clone();
		
		if (v.x / vec.x < 0) {
			if (Math.abs(vec.x) > Math.abs(v.x)) {
				v.x = 0;
			} else {
				v.x += vec.x;
			}
		}
		if (v.y / vec.y < 0) {
			if (Math.abs(vec.y) > Math.abs(v.y)) {
				v.y = 0;
			} else {
				v.y += vec.x;
			}
		}
		
		return v;
	}
	
}

export default Vector2;