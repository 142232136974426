import Vector2 from '../lib/vector2';
import PIXI, {Sprite, AnimatedSprite, Graphics, Container, Text, spine} from 'pixi.js';
import App from './clientApp';

/**
 * The client-side version of a GameObject
 */
class ClientObject {

	public type: string;
	public name: string;
	public lastUpdate: number;

	public x: number;
	public y: number;

	public dir: Vector2;
	public look: Vector2;
	public heading: Vector2;

	public health: number;
	public maxHealth: number;
	public lastHealth: number;
	public stamina: number;
	public maxStamina: number;
	public lastStamina: number;

	public you: number;
	public lastData: any;
	public input: any;

	public animation: string;
	public animationTime: number;

	// aiming
	public aimingDir: Vector2;
	public aimType: string; // line, cone, throw
	public aimDetails: any; // length of line, arc of cone, aoe splash of throw etc.

	// rendering
	public visible: boolean = true; // should this be visible?
	public asset: any; // reference to client asset for this object type
	public spine: PIXI.spine.Spine;
	public sprite: Container; //AnimatedSprite;
	public currentSprite: string;
	public sprites: any = {};
	public flipped: boolean = false;

	// ui
	public ui = new Container();
	public uiBarWidth = 37;
	public emojiOffset = 22;
	public healthBarOffset = 8;
	public staminaBarOffset = 15;



	constructor( data: any ) {
		const self = this;
		self.lastUpdate = Date.now();
		for (const i in data) {
			self[ i ] = data[i];
		}

		// get asset
		self.asset = App.assets.get( self.type );

		if (!self.asset) {
			console.error('No asset found for:', self.type);
			return;
		}

		if (self.asset.type == 'spine') {
			self.spine = new spine.Spine( self.asset.data );
			self.sprite = self.spine;
		} else
		if (self.asset.type == 'generated') {
			self.sprite = self.asset.data.clone();
		}

		// generic transforms
		if (self.asset.scale) self.sprite.scale.set( self.asset.scale, self.asset.scale );
		if (self.asset.anchor) self.sprite.pivot.set( self.asset.anchor.x, self.asset.anchor.y );


		self.sprite.visible = false; // it'll show on first tick
		self.visible = true;

		App.stage.objectsLayer.addChild( self.sprite );

		setTimeout(function() {
			self.drawUI();
		}, 50);

	}

	/**
	 * Called when object goes missing from state data, presumably from death or disconnect by players
	 */
	public destroy() {
		const self = this;
		self.sprite.destroy();
		self.ui.destroy();
		//console.log('-', self.type, 'was destroyed');
	}

	/**
	 * The main update tick for this object
	 * @param delta - time since last tick
	 */
	public tick( delta: number) {
		const self = this;

		if (!self.sprite) return; // we aren't loaded yet

		// should we be visible?
		if (self.visible && !self.sprite.visible) self.sprite.visible = true;
		if (!self.visible && self.sprite.visible) self.sprite.visible = false;

		// if state change, change animation
		const targetSprite = self.animation;

		// change sprite
		if (self.spine && self.currentSprite != targetSprite) {
			self.currentSprite = targetSprite;
			//console.log('[' + self.type + '] target sprite:', targetSprite, self.animationTime);
			try {
				self.spine.skeleton.setToSetupPose();
				self.spine.state.clearTracks();
				self.spine.state.setAnimation(0, targetSprite, true);
				if (self.animationTime) self.spine.state.update( self.animationTime / 1000 );
			} catch(e) {
				console.log('- error setting animation to:', targetSprite, e);
			}
			//self.sprites[ self.currentSprite ].position = this.sprite.position;
			//self.sprites[ self.currentSprite ].scale = this.sprite.scale;
			//self.sprite.visible = false;
			//self.sprite = this.sprites[ self.currentSprite ];
			//.sprite.visible = true;
			//if (self.sprite.play) self.sprite.gotoAndPlay(0);
		}

		// look at target
		if (self.look && self.look.magnitudeSquared()) self.lookAt( self.look );

		if (self.asset.flags.includes( 'rotateOnHeading' )) {
			const d = Vector2.FromObject( self.heading );
			self.sprite.rotation = d.angle(true);
			//console.log('- setting rotation to:', d.angle(true));
		} else
		if (self.asset.flags.includes( 'flipOnHeading' )) {

			// flip the player if necessary
			if (self.heading.x > 0 && !self.flipped) {
				self.sprite.scale.x = this.sprite.scale.x * -1;
				self.flipped = true;
			} else
			if (self.heading.x < 0 && self.flipped) {
				self.sprite.scale.x = this.sprite.scale.x * -1;
				self.flipped = false;
			}

		}

		// update position
		if (self.sprite) {
			self.sprite.position.set( Math.round(self.x), Math.round(self.y));
			self.sprite.zIndex = Math.round(this.sprite.y);

			// health/stamina bars
			self.updateUI();
			self.ui.position.set( Math.round(self.x), Math.round(self.y) );
		}
	}

	/**
	 * Points the "head" bone perpendicular to the vector, to simulate watching the target
	 * @param v - The vector to look down
	 */
	public lookAt( v: Vector2 ) {
		const self = this;

		if (!self.spine) return;
		const head = self.spine.skeleton.findSlot('Head');
		if (head) {
			// make sure look vector is flipped right because the whole object flips to face things
			if (self.asset.flags.includes('flipOnHeading') && v.x < 0) v.x = v.x * -1;

			// set head bone rotation
			head.bone.data.rotation = v.angle();
			//head.bone.update();
			//PIXI.spine.Spine.globalAutoUpdate
		}

	}

	/**
	 * Draws the player-based UI elements, namely health and stamina
	 * Width is variable, but needs to satisfy some specific rules:
	 * * Somewhere in the 20-30 range depending on character size
	 * * Width - 2 nice to be easily divisible number for health
	 * * Width - 4 needs to be divisible by 3
	 * * Width - 5 would be great if divisible by 4
	 * * Possibles:
	 *   * 28 works with 3
	 *   * 25 works with 3 and 4, but is a tad small <-- trying this
	 *   * 37 is next 3 and 4
	 */
	private drawUI(): void {
		const self = this;

		if (self.maxHealth) self.drawUIHealthBar();
		if (self.maxStamina) self.drawUIStaminaBar();
		if (self.name) self.drawEmojiName();
		if (self.type == 'player') self.drawTargeting();

		App.stage.uiLayer.addChild( self.ui );
	}

	/**
	 * Draws the targeting graphics for the object
	 * aimingDir: obj.aimingDir,
	 * aimingAt: obj.aimingAt,
	 * aimType: obj.aimType,
	 * aimDetails: obj.aimDetails
	 */
	private drawTargeting(): void {
		const self = this;

		self.drawTargetingLine();
		self.drawThrowIndicator();
	}

	/**
	 * Updates the targeting based on input data and targeting type
	 * aimingDir: obj.aimingDir,
	 * aimType: obj.aimType,
	 * aimDetails: obj.aimDetails
	 */
	private updateTargeting(): void {
		const self = this;

		//console.log('- aim:', self.aimType, self.aimingDir);
		self.updateTargetingLine();
		self.updateThrowIndicator();
	}


	/**
	 * Draws a targeting line for use with attacks
	 * TODO: Add support for line length and thickness
	 */
	private drawTargetingLine(): void {
		const self = this;

		const line = new Graphics();
		line.x = 0;
		line.y = -15;
		line.beginFill( 0xFFFFFF, .5);
		line.drawRect(0, 0, 150, 10);
		line.endFill();
		line.pivot.set( -5, 5 );
		line.visible = false;

		// add to ui
		(self.ui as any).targetLine = line;
		self.ui.addChild( line );

	}

	/**
	 * Updates targeting line based on input data
	 */
	private updateTargetingLine(): void {
		const self = this;

		const line: Graphics = (self.ui as any).targetLine;
		if (self.aimType == 'line' && line) {
			
			const angle = new Vector2(self.aimingDir.x, self.aimingDir.y).angle( true ); // radians
			line.rotation = angle;
			line.visible = true;
			if (self.aimDetails?.invalid) {
				//console.log('- invalid line', self.aimDetails);
				line.tint = 0xFF3333;
			} else {
				//console.log('- regular line');
				line.tint = 0xFFFFFF;
			}
			line.width = self.aimDetails?.length || 150;
			line.height = self.aimDetails?.thickness || 10;
		} else
		if (line) {
			line.visible = false;
		}
	}

	/**
	 * Draws an arced throw indicator
	 */
	private drawThrowIndicator(): void {
		const self = this;

		// these should be set by the ability data, but we'll do that later
		const range = 100;
		const height = 50;
		const radius = 15;

		// create container
		const throwArc = new Container();
		throwArc.visible = false;
		throwArc.x = 0;
		throwArc.y = 0;

		// draw circle for end point
		const circle = new Graphics();
		circle.x = range;
		circle.y = 0;
		circle.beginFill( 0xFFFFFF, .5);
		circle.drawEllipse(0, 0, radius, radius * 0.70); // perspective
		circle.endFill();
		(throwArc as any).circle = circle;
		throwArc.addChild( circle );

		// draw arc to the circle
		const arc = new Graphics();
		arc.x = 0;
		arc.y = 0;
		//arc.moveTo(0, 0);
		arc.lineStyle( 3, 0xFFFFFF, 0.5);
		//arc.arc( 50, 0, 50, Math.PI / 2, Math.PI / -2);
		arc.bezierCurveTo( 25, -1 * height, 75, -1 * height, 100, 0);
		(throwArc as any).arc = arc;
		throwArc.addChild( arc );

		// add to ui
		(self.ui as any).throwArc = throwArc;
		self.ui.addChild( throwArc );
	}

	private updateThrowIndicator(): void {
		const self = this;

		const throwArc: Container = (self.ui as any).throwArc;
		if (self.aimType == 'throw' && throwArc) {

			const range = self.aimDetails.range || 100;
			const height = self.aimDetails.height || -50;
			
			// calculate end point
			const scalar = range;
			const point = self.aimingDir.normalize().multiply( scalar );
			const circle = (throwArc as any).circle;
			circle.x = point.x;
			circle.y = point.y;
			circle.width = circle.height = self.aimDetails.radius;

			// calc tangets
			const arc: Graphics = (throwArc as any).arc;

			// get mid point on ground
			const midGround = point.multiply( 0.5 );
			//console.log('- mid ground:', midGround);

			// get scale projection from y-axis
			const arcScale = Math.abs(point.x) / range;
			//console.log('- arc scale:', arcScale);

			// go up by arcscale * height from mid point
			//const midAir = midGround.add( new Vector2(0, height * arcScale) );
			//console.log('- mid air:', midAir);

			const tangOne = midGround.multiply( 0.5 ).add( new Vector2(0, height * arcScale) );
			const tangTwo = midGround.multiply( 1.5 ).add( new Vector2(0, height * arcScale) );

			arc.moveTo(0, 0);
			arc.clear();
			arc.lineStyle( 3, 0xFFFFFF, 0.5);
			//console.log('- curve:', tangOne.x, tangOne.y, tangTwo.x, tangTwo.y, point.x, point.y );
			arc.bezierCurveTo( tangOne.x, tangOne.y, tangTwo.x, tangTwo.y, point.x, point.y );

			// show it
			throwArc.visible = true;
		} else
		if (throwArc) {
			throwArc.visible = false;
		}
	}

	/**
	 * Draws the name, which for guests is an emoji
	 */
	private drawEmojiName(): void {
		const self = this;

		const nA = self.name.split('#');
		const emojiText = nA[0];

		console.log('- emoji is', emojiText);
		const emoji = new Text( emojiText, {fontSize: 10, fill: '#FFFFFF'} );
		emoji.x = -0.5 * emoji.width;
		emoji.y = self.emojiOffset;
		if (!self.maxStamina) emoji.y -= 7;

		// add to ui
		(self.ui as any).emoji = emoji;
		self.ui.addChild( emoji );
	}

	/**
	 * creates the healthbar and attaches to the object
	 */
	private drawUIHealthBar(): void {
		const self = this;

		// healthbar
		const healthBar = new Container();
		healthBar.x = -0.5 * self.uiBarWidth;
		healthBar.y = self.healthBarOffset;

		// black outline
		const bg = new Graphics();
		bg.beginFill(0x000000, 1);
		bg.drawRoundedRect(0, 0, self.uiBarWidth, 7, 3);
		bg.endFill();
		healthBar.addChild( bg );

		// health fill
		const h = new Graphics();
		h.x = 1;
		h.y = 1;
		h.beginFill(0xFF3333, 1);
		h.drawRoundedRect(0, 0, self.uiBarWidth - 2, 5, 1);
		h.endFill();
		healthBar.addChild( h );
		(healthBar as any).fillBar = h;

		// shine
		const mh = new Graphics();
		mh.beginFill(0xCCCCCC, .3);
		mh.drawRoundedRect(1, 1, self.uiBarWidth - 2, 3, 1);
		mh.endFill();
		healthBar.addChild( mh );

		// add to ui
		(self.ui as any).healthBar = healthBar;
		self.ui.addChild( healthBar );
	}

	/**
	 * updates the healthbar based on state data
	 */
	private updateUIHealthBar(): void {
		const self = this;

		if (self.maxHealth && self.lastHealth != self.health) { // new health, draw it
			
			(self.ui as any).healthBar.fillBar.width = Math.round((self.health / self.maxHealth) * self.uiBarWidth - 2);
			self.lastHealth = self.health;
			
		}
	}

	/**
	 * creates the stamina bar and attaches to the object
	 */
	private drawUIStaminaBar(): void {
		const self = this;

		console.log('- drawing stam bar');

		const stambar = new Container();
		stambar.x = -0.5 * self.uiBarWidth;
		stambar.y = self.staminaBarOffset;

		// black outline
		const bg = new Graphics();
		bg.beginFill(0x000000, 1);
		bg.drawRoundedRect(0, 0, self.uiBarWidth, 7, 3);
		bg.endFill();
		stambar.addChild( bg );

		// stamina fill x3
		for (let i = 0; i <= 2; i++) {

			const f = new Graphics();
			f.x = 1 + (((this.uiBarWidth - 4) / 3) + 1) * i;
			f.y = 1;
			f.beginFill(0x222266, 1);
			f.drawRoundedRect(0, 0, (self.uiBarWidth - 4) / 3, 5, 1);
			f.endFill();
			stambar.addChild( f );
			stambar['fillBar' + i] = f;

			const h = new Graphics();
			h.x = 1 + (((self.uiBarWidth - 4) / 3) + 1) * i;
			h.y = 1;
			h.beginFill(0xFF6666, 1);
			h.drawRoundedRect(0, 0, (self.uiBarWidth - 4) / 3, 5, 1);
			h.endFill();
			stambar.addChild( h );
			stambar['fullBar' + i] = h;

			// grey shine
			const mh = new Graphics();
			mh.x = 1 + (((self.uiBarWidth - 4) / 3) + 1) * i;
			mh.y = 1;
			mh.beginFill(0xCCCCCC, .3);
			mh.drawRoundedRect(0, 0, (self.uiBarWidth - 4) / 3, 3, 1);
			mh.endFill();
			stambar.addChild( mh );
		}

		// add to ui
		(self.ui as any).staminaBar = stambar;
		self.ui.addChild( stambar );
	}

	/**
	 * Updates staminabar with state data
	 */
	private updateUIStaminaBar(): void {
		const self = this;

		if (self.maxStamina && self.lastStamina != self.stamina) { // new stamina, draw it

			// handle each bar separately
			for (let i = 0; i <= 2; i++) {

				const fullBar = (self.ui as any).staminaBar['fullBar' + i];
				const fillBar = (self.ui as any).staminaBar['fillBar' + i];

				const isFilled = self.stamina >= i + 1;
				if (isFilled) {
					fullBar.visible = true;
				} else {
					const partial = Math.max(self.stamina - i, 0);

					fullBar.visible = false;
					fillBar.width = partial * (self.uiBarWidth - 4) / 3;
				}
			}

		}
	}

	/**
	 * Updates the health and stamina bars with current values
	 */
	private updateUI(): void {
		const self = this;

		if (!self.ui.children.length) return;

		self.updateUIHealthBar();
		self.updateUIStaminaBar();
		self.updateTargeting();
	}

}

export default ClientObject;