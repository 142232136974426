import Vector2 from '../lib/vector2';
import NippleJS from 'nipplejs';
import $j from 'jquery';
import _ from 'lodash';
import App from './clientApp';

class Input {

	private static instance: Input = new Input();

	public joyManager: NippleJS.JoystickManager;
	public attackJoyManager: NippleJS.JoystickManager;
	public abilityOneJoyManager: NippleJS.JoystickManager;
	public joy: NippleJS.JoystickOutputData;
	public attackJoy: NippleJS.JoystickOutputData;
	public abilityOneJoy: NippleJS.JoystickOutputData;
	public keys: any;
	public buttons: any;
	public dir: Vector2 = new Vector2();
	public throttle: number = 0;

	init() {
		const self = this;
		// set up joystick
		self.joyManager = NippleJS.create( {
			zone: $j('#touch-container').get(0),
			color: 'yellow',
			multitouch: false
		});

		self.attackJoyManager = NippleJS.create({
			zone: $j('#button-a').get(0),
			color: 'green',
			multitouch: false
		});

		self.abilityOneJoyManager = NippleJS.create({
			zone: $j('#button-b').get(0),
			dataOnly: true,
			multitouch: false
		});


		self.joyManager.on('move', function (e, data) {
			self.joy = data;
		});
		self.attackJoyManager.on('move', function (e, data) {
			self.attackJoy = data;
		});
		self.abilityOneJoyManager.on('move', function (e, data) {
			self.abilityOneJoy = data;
		});
		
		// set up key events
		const keys = self.keys = {};
		window.onkeyup = function(e) { 
			if (App.debug) console.log('keydown:', e.keyCode);
			keys[e.keyCode] = false;
		};
		window.onkeydown = function(e:any) { keys[e.keyCode] = true; };
	}

	/**
	 * Gets the current input data ready to send to the game server
	 */
	public getInput(): any {
		const self = this;
		// reset inputs
		self.dir = new Vector2();
		self.buttons = {};

		//self.getButtons();
		self.getKeys();
		self.getJoy();
		self.getAttackJoy();
		self.getAbilityOneJoy();
		//self.getVirtualJoy();
		self.getGamepad();

		const input = {
			dir: self.dir.toFixed(3),
			throttle: +self.throttle.toFixed(3),
			buttons: self.buttons
		};

		if (App.stage.player) App.stage.player.input = input;

		return input;
	}

	/**
	 * Gets the input from html5 gamepad UI https://html5gamepad.com/
	 * - example: xb360 controller
	 * - pad.axes[0,1] = left thumb
	 * - pad.axes[2,3] = right thumb
	 * - pad.buttons[0-16] = {value: 0-1, pressed: false, touched: false}
	 */
	getGamepad() {
		const self = this;
		if (!navigator.getGamepads) return;
		const pads = navigator.getGamepads();

		const pad = _.find(pads, function(p) { return p != null; });

		if (!pad) return;

		// deadzone
		const dx = Math.abs(pad.axes[0]) > 0.15 ? pad.axes[0] : 0;
		const dy = Math.abs(pad.axes[1]) > 0.15 ? pad.axes[1] : 0;

		const v = new Vector2( dx, dy );

		// buttons
		if (pad.buttons[0].value || pad.buttons[7].value > .5) { // A and RT
			self.buttons.a = {
				dir: v.normalize(),
				mag: v.magnitude() * 100
			};
		} else
		if (pad.buttons[1].value || pad.buttons[6].value > .5) { // B and LT
			self.buttons.b = {
				dir: v.normalize(),
				mag: v.magnitude() * 100
			};
		} else
		if (v.magnitudeSquared()) {
			self.dir = v.normalize();
			self.throttle = v.magnitude();
		}
		
	}

	/**
	 * Gets the virtual joystick values and stores to self.dir and self.throttle
	 */
	getJoy() {
		const self = this;

		
		if (self.joy) {

			const j: NippleJS.Joystick = self.joyManager.get( self.joy.identifier );
			if (!j) return;
			//console.log( self.joy.position, self.joy.frontPosition );
			self.dir = new Vector2( self.joy.vector.x, self.joy.vector.y * -1 );
			self.throttle = self.dir.magnitude();
			self.dir = self.dir.normalize(); // if this wasn't normalized before, let's make sure

			//console.log( '- pos:', self.joy.vector );

			// if the draw of the joystick is too far, let's move the whole thing for more natural movement
			if (self.joy.raw.distance > 100) {
				//console.log( '- pos:', j.position, j.frontPosition );

				const delta: Vector2 = self.dir.multiply( self.joy.raw.distance - 100 );
				//console.log('- movign joystick by:', delta);
				if (j) {
					j.position = {x: j.position.x + delta.x, y: j.position.y + delta.y};
					j.el.style.left = j.position.x + 'px';
					j.el.style.top = j.position.y + 'px';
					self.joy.raw.distance = 100;
				}
			}
		}

	}

	/**
	 * Gets the virtual joystick values from the attack button
	 */
	getAttackJoy() {
		const self = this;
		
		if (self.attackJoy) {

			const j: NippleJS.Joystick = self.attackJoyManager.get( self.attackJoy.identifier );
			if (!j) return;

			const dir = new Vector2( self.attackJoy.vector.x, self.attackJoy.vector.y * -1 );
			const mag = Math.min(100, self.attackJoy.raw.distance);

			//console.log( '- pos:', self.attackJoy.vector, dir, mag );

			self.buttons.a = {
				dir: dir,
				mag: mag
			};

		}

	}

	/**
	 * Gets the virtual joystick values from the attack button
	 */
	getAbilityOneJoy() {
		const self = this;
		
		if (self.abilityOneJoy) {

			const j: NippleJS.Joystick = self.abilityOneJoyManager.get( self.abilityOneJoy.identifier );
			if (!j) return;

			const dir = new Vector2( self.abilityOneJoy.vector.x, self.abilityOneJoy.vector.y * -1 );
			const mag = Math.min(100, self.abilityOneJoy.raw.distance);

			//console.log( '- pos:', self.abilityOneJoy.vector, dir, mag );

			self.buttons.b = {
				dir: dir,
				mag: mag
			};

		}

	}

	getKeys() {
		const self = this;
		// heading
		let h = new Vector2(0, 0);
		if (self.keys[87]) h = h.add( new Vector2(0, -1)); // W
		if (self.keys[65]) h = h.add( new Vector2(-1, 0)); // A
		if (self.keys[83]) h = h.add( new Vector2(0, 1)); // S
		if (self.keys[68]) h = h.add( new Vector2(1, 0)); // D
		if (self.keys[32]) {
			//self.attackSignal = true; // Space
			self.buttons.a = {
				dir: h.normalize(),
				mag: h.magnitude() * 100
			};
			return;
		}
		if (self.keys[81]) {
			//self.parrySignal = true; // Q
			self.buttons.b = {
				dir: h.normalize(),
				mag: h.magnitude() * 100
			};
			return;
		}

		if (h.magnitudeSquared() == 0) {
			self.throttle = 0;
			return;
		} else {
			self.dir = h.normalize();
			self.throttle = 1;
		}

		//console.log(self.keys, self.dir, self.throttle);
	}

	public static Get(): Input {
		return Input.instance;
	}
}

export default Input.Get();