import { Server } from "http";


class ServerTick {
	public tickNum: number;
	/** @var in milliseconds */
	public time: number;

	public data: any[];

	constructor( tickNum: number, data: any[]) {
		this.tickNum = tickNum;
		this.data = data;

		this.time = Date.now();
	}
}

export default ServerTick;