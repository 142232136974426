import BosonComponent from '../component';
import Site from '../../site';

/**
 * Gamplay controls UI
 */
class Login extends BosonComponent {

	public name: string = 'site/login';

	public data = {
		loading: false,
		showBox: 'login',
		message: ''
	}

	public constructor() {
		super();
		
		this.initComponent();
	}

	public init() {
		const self = this;
		super.init();

		console.log('- login init');
	}

	/**
	 * Pressing the guest login button
	 */
	public async guestLogin() {
		const self = this;
		console.log('- guest login button');
		self.data.loading = true;

		const u = await Site.api('user/createGuestUser');
		console.log('- response from create guest:', u);

		self.data.showBox = 'message';
		self.data.message = '<i class="fa fa-spin fa-refresh" style="float: right; margin: 3px; color: #6F6;"></i>Welcome, your guest name will be ' + u.name;

		setTimeout(function() {
			window.location.reload();
		}, 2000);
	}

}

export default Login;