import * as PIXI from 'pixi.js';
import Vector2 from '../lib/vector2';

/**
 * Class for holding fx generators that are written using PIXI.Graphics
 */
class FX {

	public static ping(): PIXI.Graphics {
		const bigRadius = 20;
		const littleRadius = 5;
		const ratio = 0.7;

		const p = new PIXI.Graphics();
		
		p.beginFill( 0xF00F00, .3 );
		p.drawEllipse( 0, 0, littleRadius, littleRadius * ratio );
		p.endFill();
		p.lineStyle( 3, 0xFFFFFF, 0.3);
		p.drawEllipse( 0, 0, bigRadius, bigRadius * ratio );

		return p;
	}

	public static shieldthrow(): PIXI.Graphics {

		const s = new PIXI.Graphics();
		s.beginFill( 0xFFCCCC, .5);
		s.drawEllipse(0, 0, 20, 15);
		s.endFill();
		return s;
	}

	public static swordslash(): PIXI.Graphics {
		const y = 0;
		const radius = 50;
		const offset = -20;
		const angle = 60 * Vector2.TO_RADIANS;

		const s = new PIXI.Graphics();
		/*
		// identification dot
		s.beginFill( 0xF00F00, .8 );
		s.drawEllipse( 0, 0, 5, 5 );
		s.endFill();*/
		s.moveTo(0, y);
		s.beginFill( 0xFFFFFF, .5 );
		s.arc( offset, y, radius, angle * -0.5, angle * 0.5, false );
		s.endFill();
		return s;
	}

}

export default FX;