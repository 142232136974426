import * as PIXI from 'pixi.js';
window.PIXI = PIXI;
import 'pixi-spine';
import Input from './input';
import Site from '../site';
import Controls from '../components/game/controls.component';
import Stage from './stage';
import ClientMap from './clientMap';
import _ from 'lodash';
import ObjectData from './objectData';

/**
 * Minmo Client Application                                          
 */
class App {

	public static instance: App = new App();

	public version : string = '0.3';
	public debug : boolean = false;
	public sheets : any = {};
	public spines: any = {};
	public game : PIXI.Application;
	public assets: Map<string, PIXI.Container> = new Map();
	public loader : PIXI.Loader;
	public stage: Stage;
	public connection: any;
	public socket?: WebSocket;
	public input: any = Input;
	private lastInput: any = null;
	private lastInputTime: number = 0;

	// server tick data for interpolation
	public pingInterval: any;
	public pings: number[] = [];
	public avgPing: number = 0;
	public serverTickRate: number = 20; // ms
	public interpDelay: number = 80;

	public map: ClientMap;

	constructor() {}

	/**
	 * Sets up pixi.js and starts loading assets
	 * @param connection - the connection info from logging in
	 */
	public async init( connection: any ) {
		const app = this;
		app.connection = connection;
		(window as any).app = app;
		console.log('- starting up version: ', app.version);
		app.game = new PIXI.Application({
			width: screen.width,
			height: screen.height,
			backgroundColor: 0x119119,
			antialias: true,
			autoDensity: true,
			resolution: window.devicePixelRatio
		});
		//PIXI.settings.ROUND_PIXELS = true;
		PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.NEAREST;
		window.document.body.appendChild(app.game.view);
		
		//this.game.renderer.backgroundColor = 0x889D24;
		app.game.renderer.view.style.position = 'absolute';
		app.game.renderer.view.style.display = 'block';
		app.game.renderer.resize(window.innerWidth, window.innerHeight);

		app.stage = new Stage();

		await app.loadAssets();

		app.stage.load();
		app.game.ticker.add( app.tick );
		app.connect();
		app.input.init();

		app.pingInterval = setInterval( app.ping, 1000 );


		/*PIXI.Loader.shared.onError.add(e => { console.error(e); })


		PIXI.Loader.shared
			.add('base_sheet', '../images/sprites/spritesheetv2.json')
			.add('skeleton_sheet', '../images/sprites/spritesheet_skeleton.json')
			.add('hero_spine', '../images/sprites/hero_v3.json')
			.load(function() {
				console.log('- shared resources:', PIXI.Loader.shared.resources);
				app.sheets.base = PIXI.Loader.shared.resources.base_sheet.spritesheet;
				app.sheets.skeleton = PIXI.Loader.shared.resources.skeleton_sheet.spritesheet;
				app.spines.hero = PIXI.Loader.shared.resources.hero_spine.spineData;
				app.stage.load();

				app.game.ticker.add( app.tick );
				app.connect();
				app.input.init();
			});
		*/
	}

	/**
	 * Loads assets defined in objectData.ts
	 */
	public async loadAssets(): Promise<void> {
		const app = this;
		app.loader = PIXI.Loader.shared;
		app.loader.onError.add(e => { console.error(e); })

		for (const i in ObjectData) {
			const o: any = ObjectData[i];
			if (o.type == 'generated') {
				const g: PIXI.Container = (o.src as Function)();
				o.data = g;
				app.assets.set( o.name, o );
			} else {
				if (o.count) {
					for (let ii = 1; ii <= o.count; ii++) {
						const add = ii == 1 ? '' : '-' + ii;
						const src = o.src.replace( '{n}', add);
						//console.log(o);
						app.loader.add(o.name + ii, src);
					}

					if (o.alts) {
						const adds = ['_01', '_11', '_10'];
						for (const add of adds) {
							const src = o.src.replace('{n}', add);
							app.loader.add(o.name + add, src);
						}
					}

				} else {
					app.loader.add( o.name, o.src );
				}
			}
		}
		return new Promise(function( resolve, reject) {
			app.loader.load( function() {
				console.log('- loaded resources:', app.loader.resources);

				for (let i in app.loader.resources) {
					const r = app.loader.resources[ i ];
					const lastChar = i.charAt( i.length - 1 );
					const variationChar = i.charAt( i.length - 3);
					if (variationChar == '_') {
						i = i.slice(0, -3);
					} else
					if (!isNaN(parseFloat(lastChar))) { // is numeric, so its a tile alt
						i = i.slice(0, -1);
					}
					const o: any = _.find(ObjectData, function(o) { return o.name == i; });

					if (!o) continue; // not a primary asset

					//console.log('- parsing resource:', r, o, i);
					// save relevate loaded data
					o.data = r.spritesheet || r.spineData || r.texture;
					if (!o.data) console.log('- resource data not found:', o);
					app.assets.set( i, o );
					
				}

				resolve();
			});
		});

	}

	/**
	 * Resizes game field when window is resized
	 */
	public resize() {
		const app = this;
		app.game.renderer.resize(window.innerWidth, window.innerHeight);
	}


	/**
	 * Client render tick + input sending
	 * @param delta - seconds since last tick
	 */
	public tick(delta : number) {

		const app = App.Get(); // scope is wrong, gotta get the singleton the hard way
		if (!app.socket) return;

		const input = app.input.getInput();
		// NOTE: Might be worth putting a timeout on lastInput in case something gets stuck
		if (JSON.stringify( input ) != app.lastInput || Date.now() - app.lastInputTime > 60000 ) { // let's make sure we're not just bombarding with data
			app.send('input', input );
			app.lastInput = JSON.stringify( input );
			app.lastInputTime = Date.now();
		}

		app.stage.tick( delta );
	}

	/**
	 * Pings the server to find out round trip time, to possibly adjust our render delay
	 */
	public ping() {
		const app = App.Get();

		app.send('ping', Date.now());
	}

	/**
	 * Handles the server's response to our ping
	 * @param message - should be the time we sent in ping
	 */
	public pong( message: any) {
		const app = App.Get();

		const ping = Date.now() - parseInt(message);
		//console.log('- ping:', ping, 'ms');
		app.pings.push( ping );
		app.pings.slice(-10);

		app.avgPing = Math.round( app.pings.reduce(function(a, b) { return a + b; }) / app.pings.length );
		Site.main.game.controls.data.ping = app.avgPing;
	}

	/**
	 * Sends message back to server
	 * @param messageType - the type of message
	 * @param data - json of the data to send
	 */
	public send( messageType: string, data: any ) {
		const app = this;
		
		//console.log('- sending:', data);
		if (app.socket) {
			app.socket.send( app.connection.connectionId + ':' + messageType + ':' + JSON.stringify( data ) );
		} else {
			console.log('- disconnected, not sending socket data');
		}
	}

	/**
	 * Connects to the world server with a socket connection
	 */
	public connect() {
		const app = this;
		console.log('- connecting to:', Site.socketDomain + ':' + app.connection.worldId );
		const url = 'wss://' + Site.socketDomain + ':' + app.connection.worldId;
		const c = new WebSocket(url);

		c.onopen = function() {
			console.log('[WO] socket connection open');
			app.socket = c;
			c.send(app.connection.connectionId + ':hi');
		};

		c.onclose = function() {
			console.log('[WO] socket connection closed, releasing');
			app.socket = undefined;
		};

		c.onerror = function(e) {
			console.log('[WO] websocket error:', e);
		};

		c.onmessage = function(e) {
			//console.log('[WO] websocket says:', e.data);
			const mA = e.data.split(':');
			const mType = mA.shift();
			const extra = mA.shift();
			const message = JSON.parse( mA.join(':') );
			//console.log('[WO] websocket says:', mType, message);

			switch( mType ) {
				case 'tickrate':
					app.serverTickRate = parseInt( message );
					//app.interpDelay = app.serverTickRate * 2.5;
				break;

				case 'objdump':
					app.stage.handleObjectDump( message );
				break;

				case 'newobj':
					app.stage.handleObjectDump( [message] );
				break;

				case 'event':
					app.stage.handleEvent( message );
				break;

				case 'state':
					//console.log('- state', _.find(message, function(m) { return m.you; }) );
					app.stage.handleState( extra, message );
				break;

				case 'pong':
					app.pong( message );
				break;

				default:
					console.log('- no handler for:', e.data);
				break;
			}
		};
	}

	/**
	 * Singleton helper
	 */
	static Get(): App {
		return App.instance;
	}

}

export default App.Get();