import FX from './fx';

const ObjectData = [
	// Spines
	{
		name: 'player',
		type: 'spine',
		src: '../images/sprites/hero_v3.json',
		scale: 0.08,
		flags: ['flipOnHeading']
	},
	{
		name: 'enemy/skeleton',
		type: 'spine',
		src: '../images/sprites/Skeleton.json',
		scale: 0.5,
		flags: ['flipOnHeading']
	},

	// fx
	{
		name: 'fx/swordslash',
		type: 'generated',
		src: FX.swordslash,
		anchor: {x: 0, y: 0},
		flags: ['rotateOnHeading']
	},
	{
		name: 'fx/shieldthrow',
		type: 'generated',
		src: FX.shieldthrow,
		anchor: { x: 0, y: 0 },
		flags: ['rotateOnHeading']
	},

	// map data
	{
		name: 'tilekey/test',
		type: 'tilekey',
		src: '../images/maps/road_map_test.png'
	},
	// tiles
	{
		name: 'tile/d', // catch all
		type: 'tile',
		count: 2,
		src: '../images/maps/tiles/Mtile-d_dddd{n}.png'
	},
	{
		name: 'tile/g',
		type: 'tile',
		count: 4,
		src: '../images/maps/tiles/Mtile-g_gggg{n}.png'
	},
	{
		name: 'tile/g_dggd',
		type: 'tile',
		count: 1,
		alts: [2, 4], // variation positions (TL = 1, TR = 2, BR = 3, BL = 4)
		src: '../images/maps/tiles/Mtile-g_dggd{n}.png'
	},
	{
		name: 'tile/g_ddgg',
		type: 'tile',
		count: 1,
		alts: [1, 3],
		flip: 1,
		src: '../images/maps/tiles/Mtile-g_dggd{n}.png'
	},
	{
		name: 'tile/g_dggg',
		type: 'tile',
		count: 2,
		alts: [1, 2],
		src: '../images/maps/tiles/Mtile-g_dggg{n}.png'
	},
	{
		name: 'tile/g_ggdd',
		type: 'tile',
		count: 1,
		alts: [1, 3],
		src: '../images/maps/tiles/Mtile-g_ggdd{n}.png'
	},
	{
		name: 'tile/g_gddg',
		type: 'tile',
		count: 1,
		alts: [2, 4],
		flip: 1,
		src: '../images/maps/tiles/Mtile-g_ggdd{n}.png'
	},
	{
		name: 'tile/g_ggdg',
		type: 'tile',
		count: 2,
		alts: [3, 4],
		src: '../images/maps/tiles/Mtile-g_ggdg{n}.png'
	},
	{
		name: 'tile/g_gggd',
		type: 'tile',
		count: 1,
		alts: [1, 4],
		src: '../images/maps/tiles/Mtile-g_gggd{n}.png'
	},
	{
		name: 'tile/g_gdgg',
		type: 'tile',
		count: 1,
		alts: [2, 3],
		flip: 1,
		src: '../images/maps/tiles/Mtile-g_gggd{n}.png'
	}
];

export default ObjectData;