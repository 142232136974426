import Vue from 'vue';
import BosonComponent from '../component';

/**
 * Gamplay controls UI
 */
class Controls extends BosonComponent {

	public name: string = 'game/controls';
	public extra: string = 'var that will not be used.';

	public data = {
		ping: 0
	};

	public constructor() {
		super();
		this.initComponent();
	}

	public init() {
		const self = this;
		super.init();

		console.log('- controls init');
	}

	public reload() {
		window.location.reload();
	}


}

export default Controls;