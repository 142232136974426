import Main from './components/site/main.component';
import $j from 'jquery';

class Site {

	private static instance : Site = new Site();
	public debug: boolean = true;
	public params = new URLSearchParams( window.location.search );
	public domain: string = this.params.get('local') ? 'https://localhost:4000' : 'https://minimum.games:4000';
	public socketDomain: string = this.params.get('local') ? 'localhost' : 'minimum.games';
	public lastApi: any = null;
	public main: Main;

	constructor() {}

	public init() {
		const self = this;

		console.log('- site init');

		// update touch events to fix scrolling issues
		document.body.addEventListener('touchmove', Site.prevent, {passive: false});

		// lets attach the main site component
		const main = new Main();
		self.main = main;
		main.mount( '#main' );
		main.init();
		
	}

	public static prevent(e:any) {
		e.preventDefault();
	}

	public static Get(): Site {
		return Site.instance;
	}

	/**
	 * Calls the minmo server api.
	 * @param path - the api path, ex. `user/login`
	 * @param data - key/value object to send as post data
	 * @param silent - whether or not to log stuff, if it happens a lot
	 * @return the response json
	 */
	public async api(path: string, data: any = {}, silent: boolean = false): Promise<any> {
		const self = this;
		if (!path) {
			console.log('ERROR: api call needs path');
			return null;
		}

		
		const r = await $j.post(self.domain + '/api/' + path, data, 'json');
		if (self.debug && !silent) console.log('%c[api]', 'color: #CCC;', path, r);
		if (self.debug && !silent) self.lastApi = r;

		return r;
		
	}

}

export default Site.Get();