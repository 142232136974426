import BosonComponent from '../component';
import Login from './login.component';
import Site from '../../site';
import Game from '../game/game.component';

class Main extends BosonComponent {

	public name: string = 'site/main';
	public game: Game;

	/**@var the shared data between the class and the vue component */
	public data = {
		message: 'ice cream sandwich'	
	};

	public constructor() {
		super();
		
		this.initComponent();
	}

	public async init() {
		const self = this;
		super.init();

		console.log('- main init');

		// cookie check for sanity?
		//await self.cookieCheck();

		// check for login
		const conn = await self.autolog();
		if (conn)  {
			//show the game!
			console.log('- we have a connection, let\'s play!');
			const game = new Game();
			self.game = game;
			game.connection = conn;
			game.mount( '#main ');
			game.init();

		} else {

			// show login
			const login = new Login();
			login.mount( '#main' );
			login.init();

		}
	}

	private async cookieCheck() {
		const c = await Site.api('user/cookieTest');
		console.log(c);
	}

	/**
	 * Attempts to auto login using server-only cookies
	 * @return connection info
	 */
	private async autolog(): Promise<any> {
		const conn = await Site.api('user/autoLogin');
		if (conn.error) return null;

		return conn;
	}

	

}

export default Main;