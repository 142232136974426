import Vue, {Component} from 'vue';
import Templates from '../templates';

class BosonComponent {

	/**@var the component / template name*/
	public name : string;

	/**@var the Vue instance for this component*/
	public vm : Vue;

	/**@var the shared data between the class and the vue component */
	public data : any = {};

	/**
	 * Initializes the Vue component based on the child classes' settings.
	 */
	public initComponent() {
		const self = this;
		console.log('-', self.name, 'component init', Object.getPrototypeOf(self).constructor.name);
		let template: Component;
		try {
			template = Templates.get( self.name );
		} catch(e) {
			console.error(e);
			throw new Error('No such Vue file:  /templates/' + self.name + '.vue');
		}

		// find methods
		const methods = Reflect.ownKeys(Object.getPrototypeOf(self));
		const methodsToAttach = {};
		for(const m in methods) {
			//console.log('- class member:', m, methods[m], typeof self[methods[m]], self[methods[m]].constructor.name);
			methodsToAttach[ methods[m] ] = function(...args: any) { self[ methods[m] ](...args); };
		}


		// convert name
		const niceName = self.name.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) { 
			return index == 0 ? word.toLowerCase() : word.toUpperCase(); 
		}).replace(/[^a-zA-z]/g, '');

		// the (as any) is because for some reason it thinks it's read only
		(template as any).name = niceName + 'Component';
		(template as any).data = function() { return self.data };
		(template as any).methods = methodsToAttach;
		self.vm = new Vue({ render: createElement => createElement( template ) });
	}

	/**
	 * Spot to initialize internal components or settings
	 */
	public init() {

	}

	/**
	 * Mounts the component to a particular element.
	 * @param el - The element query, like css
	 */
	public mount(el: string): void {
		const self = this;
		const container = document.createElement('div');
		const div = document.querySelector( el );
		if (!div) console.error('No div found for', el);

		div.appendChild( container );
		self.vm.$mount( container );

		// trigger this on next tick, so it's actually mounted
		setTimeout(function() {
			self.onMount();
		}, 1);
	}

	/**
	 * Run after mounting, in case any initialization requires the dom to be active
	 */
	public onMount(): void {}

	/**
	 * Destroys the component's Vue component, and theoritically lines itself up for garbage collection
	 */
	public destroy(): void {
		const self = this;
		self.vm.$destroy();
		self.vm.$el.remove();
		self.vm = null;
	}
}

export default BosonComponent;