// @ts-nocheck
import PIXI, {Sprite, Graphics, Texture, Container, DisplayObject} from 'pixi.js';
import App from './clientApp';
import Noise from '../lib/noise';

class ClientMap {

	public sprite: Container;
	public sprites: any = {};

	// seeds
	public treeSeed: number = 233;
	public grassSeed: number = 111;
	public rockSeed: number = 4134;
	public bushSeed: number = 4135;

	// plates - we can't show textures larger than 1024 on some browsers
	public plateSize: number = 900; // should be multiple of tileSize * tileScale
	public plates = {};

	// tile key
	public tileSize: number = 900;
	public tileOffset: number = 50;
	public tileScale: number = 0.1;
	public tileKeyColors = {
		'327345': 'g', // grass
		'e4a672': 'd', // dirt
	};

	constructor() {
		// load tile key
		const tileKey = App.loader.resources['tilekey/test'].data;
		const tcanvas: HTMLCanvasElement = document.createElement('canvas');
		tcanvas.width = tileKey.width;
		tcanvas.height = tileKey.height;

		tcanvas.getContext('2d').drawImage(tileKey, 0, 0, tileKey.width, tileKey.height);
		const tctx = tcanvas.getContext('2d');

		// generate map plates (must be smaller to deal with iOS)
		this.sprite = new Container();
		const numPlatesX = Math.ceil((tileKey.width * this.tileSize * this.tileScale) / this.plateSize);
		const numPlatesY = Math.ceil((tileKey.height * this.tileSize * this.tileScale) / this.plateSize);

		for (let y = 0; y < numPlatesY; y++) {
			for (let x = 0; x < numPlatesX; x++) {
				const plate: HTMLCanvasElement = document.createElement('canvas');
				plate.width = this.plateSize;
				plate.height = this.plateSize;
				const p = new Sprite(Texture.from(plate));
				p.zIndex = 1;
				p.x = x * this.plateSize;
				p.y = y * this.plateSize;
				this.sprite.addChild(p);
				this.plates[x + '_' + y] = p;
			}
		}

		console.log( this.plates );

		// build map onto plates
		
		for (let y = 0; y < tileKey.height; y++) {
			for (let x = 0; x < tileKey.width; x++) {

				let cx = x * this.tileSize * this.tileScale;
				let cy = y * this.tileSize * this.tileScale;

				const plate = this.getPlateFor(cx, cy);
				//console.log('- found plate:', plate, plate?.position, 'for', cx, cy);
				const ctx = plate.texture.baseTexture.resource.source.getContext('2d');

				cx -= plate.x;
				cy -= plate.y;

				const baseTileType = this.getTileType(tctx, x, y);
				const topTileType = y <= 0 ? baseTileType : this.getTileType(tctx, x, y - 1);
				const rightTileType = x >= tileKey.width - 1 ? baseTileType : this.getTileType(tctx, x + 1, y);
				const bottomTileType = y >= tileKey.height - 1 ? baseTileType : this.getTileType(tctx, x, y + 1);
				const leftTileType = x <= 0 ? baseTileType : this.getTileType(tctx, x - 1, y);

				const tileCode = baseTileType + '_' + topTileType + rightTileType + bottomTileType + leftTileType;
				//console.log('- tile code:', tileCode);

				// find tile object
				const tile = App.assets.get('tile/' + tileCode) || App.assets.get('tile/' + baseTileType);
				//console.log('- tile:', tile);

				let imgKey: string;
				let imgData: any;
				if (tile.alts) {
					const vcs = [];
					for (const pos of tile.alts) {
						let col: string;
						switch(pos) {
							case 1:
								col = y <= 0 || x <= 0 ? baseTileType : this.getTileType(tctx, x - 1, y - 1);
							break;
							
							case 2:
								col = y <= 0 || x >= tileKey.width - 1 ? baseTileType : this.getTileType(tctx, x + 1, y - 1);
							break; 
						
							case 3:
								col = y >= tileKey.height - 1 || x >= tileKey.width - 1 ? baseTileType : this.getTileType(tctx, x + 1, y + 1);
							break;

							case 4:
								col = y >= tileKey.height - 1 || x <= 0 ? baseTileType : this.getTileType(tctx, x - 1, y + 1);
							break;
						}
						vcs.push(col == baseTileType ? '1' : '0');

					}
					const vcsString = vcs.join('');
					if (vcsString != '00') {
						//console.log('- loading', tile.name + '_' + vcsString);
						imgKey = tile.name + '_' + vcsString;
						imgData = App.loader.resources[tile.name + '_' + vcsString].data;
					}
				}

				if (!imgData) {
					const num = Math.floor(Math.random() * tile.count) + 1;
					imgKey = tile.name + num;
					imgData = App.loader.resources[tile.name + num].data;
				}
				
				if (tile.flip) {
					ctx.save();
					ctx.setTransform(-1, 0, 0, 1, this.tileSize * this.tileScale, 0);
				}
				
				ctx.drawImage( imgData, this.tileOffset, this.tileOffset, this.tileSize, this.tileSize, tile.flip ? -1 * cx : cx, cy, this.tileSize * this.tileScale, this.tileSize * this.tileScale);

				if (tile.flip) {
					ctx.restore();
				}

				// debug
				/*
				ctx.font = '12px serif';
				ctx.fillStyle = 'white';
				ctx.fillText( imgKey , cx + 10, cy + 30);
				ctx.fillText( x + ',' + y, cx + 10, cy + 45);
				*/
				

			}
		}

		// split the big map sprite into plates
		/*this.sprite = new Container();
		const numPlatesX = Math.ceil( canvas.width / this.plateSize );
		const numPlatesY = Math.ceil( canvas.height / this.plateSize );
		for (let y = 0; y < numPlatesY; y++) {
			for (let x = 0; x < numPlatesX; x++) {
				const plate: HTMLCanvasElement = document.createElement('canvas');
				plate.width = this.plateSize;
				plate.height = this.plateSize;
				const plateContext = plate.getContext('2d');
				console.log('- drawing plate with:', x * this.plateSize, y * this.plateSize, this.plateSize, this.plateSize, 0, 0, this.plateSize, this.plateSize);
				plateContext.drawImage( canvas, x * this.plateSize, y * this.plateSize, this.plateSize, this.plateSize, 0, 0, this.plateSize, this.plateSize);
				const p = new Sprite( Texture.from(plate));
				this.plates.push( p );
				p.zIndex = 1;
				p.x = x * this.plateSize;
				p.y = y * this.plateSize;
				this.sprite.addChild( p );
				
			}
		}*/

		//this.sprite = new Sprite( Texture.from(canvas) );
		//this.sprite = map;

		/*this.sprites = {
			'tree': new Sprite( App.sheets.base.textures['tree.png'] ),
			'rock': new Sprite( App.sheets.base.textures['rock.png'] ),
			'bush': new Sprite( App.sheets.base.textures['bush.png'] ),
			'grass': new Sprite( App.sheets.base.textures['grass_tufts.png'] )
		};*/

	}

	/**
	 * Generate the map features from seed and perlin noise and adds to world
	 */
	generateAndAdd() {
		const self = this;

				
		/*const gridX = 500;
		const gridY = 500;

		console.log('- grid size:', self.sprite.width / gridX);

		// perlins
		const grassPerlin = Noise( this.grassSeed );
		const rockPerlin = Noise( this.rockSeed );
		const bushPerlin = Noise( this.bushSeed );
		const treePerlin = Noise( this.treeSeed );

		// tree shadow
		const shadow = new Graphics();
		shadow.beginFill(0x000000, .4);
		shadow.drawEllipse(0, 0, 30, 10);
		shadow.endFill();

		for (let y = -1; y <= gridY; y++) {
			for (let x = -1; x <= gridX; x++) {
				const ge = Math.round(grassPerlin.simplex2(x, y) * 500) + 500;
				if (ge > 980) {
					const grass = Sprite.from( self.sprites.grass.texture );
					grass.anchor.set(0.5, 0.5);
					grass.scale.set(0.8, 0.8);
					grass.x = Math.round( x * self.sprite.width / gridX );
					grass.y = Math.round( y * self.sprite.height / gridY );
					grass.zIndex = 1;
					self.sprite.addChild( grass );
				}

				/*if (ge > 995) { // spawn a skeleton

					let skeleton = new Skeleton();
					skeleton.loadAndAdd(x * self.sprite.width / gridX, y * self.sprite.height / gridY);

				}

				const re = Math.round(rockPerlin.simplex2(x, y) * 500) + 500;
				if (re > 970) {
					const rock = Sprite.from( self.sprites.rock.texture );
					const rockScale = rockPerlin.simplex2(y, x) * .5 + 0.5;
					rock.anchor.set(1, 1);
					rock.scale.set(rockScale, rockScale);
					rock.x = Math.round( x * self.sprite.width / gridX );
					rock.y = Math.round( y * self.sprite.height / gridY );
					rock.zIndex = 1;
					self.sprite.addChild( rock );
				}

				const be = Math.round(bushPerlin.simplex2(x, y) * 500) + 500;
				if (be > 990) {
					const bush = Sprite.from( self.sprites.bush.texture );
					const bushScale = bushPerlin.simplex2(y, x) * .1 + 0.95;
					bush.anchor.set(.5, .58);
					bush.rotation = -5 * Math.PI / 180; // deg to rad
					//bush.scale.set(bushScale, bushScale);
					bush.x = Math.round( x * self.sprite.width / gridX );
					bush.y = Math.round( y * self.sprite.height / gridY );
					bush.zIndex = bush.y;
					App.stage.objects.addChild( bush );

					const s = shadow.clone();
					s.x = Math.round( x * self.sprite.width / gridX ) + 2;
					s.y = Math.round( y * self.sprite.height / gridY ) + 23;
					s.scale.set(1, .5);
					s.zIndex = 1;
					self.sprite.addChild( s );
				}

				const te = Math.round(treePerlin.simplex2(x, y) * 500) + 500;
				if (te > 960) {
					const tree = Sprite.from( self.sprites.tree.texture );
					tree.anchor.set(0.5, 0.75);
					tree.scale.set(0.5, 0.5);
					tree.x = Math.round( x * self.sprite.width / gridX );
					tree.y = Math.round( y * self.sprite.height / gridY );
					tree.zIndex = tree.y;
					App.stage.objects.addChild( tree );

					const s = shadow.clone();
					s.x = Math.round( x * self.sprite.width / gridX ) - 3;
					s.y = Math.round( y * self.sprite.height / gridY ) + 18;
					s.zIndex = 1;
					self.sprite.addChild( s );
				}
			}
		}*/

		self.sprite.zIndex = 1;
		App.stage.backgroundLayer.addChild( self.sprite );
	}

	/**
	 * Return the plate sprite for given map coordinates
	 * @param x - x-coordinate on map
	 * @param y - y-coordinate on map
	 * @return the plate sprite for that section
	 */
	private getPlateFor(x: number, y: number): Sprite {
		const px = Math.floor(x / this.plateSize);
		const py = Math.floor(y / this.plateSize);

		return this.plates[px + '_' + py];
	}

	/**
	 * Gets the tile type from a tile key at certain coordinates
	 * @param x - x-coordinate of the tile key
	 * @param y - y-coordinate of the tile key
	 * @return the tile type
	 */
	private getTileType(ctx: CanvasRenderingContext2D, x: number, y: number): string {
		const pixelData = ctx.getImageData(x, y, 1, 1).data;
		const color = ((pixelData[0] << 16) + (pixelData[1] << 8) + pixelData[2]).toString(16);
		//console.log('- color from', x, y,': #' + color);

		const tileType = this.tileKeyColors[color];
		if (!tileType) throw new Error('- unidentified color in tile key: ' + color);

		return tileType;
	}

	/**
	 * GameObject Tick
	 * - any idle map animations
	 */
	tick(delta: number) {
	
	}

}

export default ClientMap;