import Vue, {Component} from 'vue';
// @ts-ignore
import templates from '../templates/*/*.vue';

class Templates {
	public static instance: Templates = new Templates();

	public vues : any;

	constructor() {
		const self = this;
		//console.log('- new temps:', templates);
		self.vues = templates;
	}

	/**
	 * Gets a template given a template path
	 * @param path - template path to look up
	 * @return the component template
	 */
	public get( path: string ) : Component {
		const self = this;

		// parse the path
		const pathA = path.split('/');

		// load template in correct depth. Probably a better way to do this, but I don't want to.
		let t = null;
		if (pathA.length == 1) t = self.vues[ pathA[0] ];
		if (pathA.length == 2) t = self.vues[ pathA[0] ][ pathA[1] ];
		if (pathA.length == 3) t = self.vues[ pathA[0] ][ pathA[1] ][ pathA[2] ];
		
		if (t) return t;

		throw new Error('No template found by that name: ' + path);
	}

	public static Get(): Templates {
		return Templates.instance;
	}
}

export default Templates.Get();